import React, {useContext,useRef,useEffect} from 'react'
import useState from 'react-usestateref'
import Companyname from '../navbar/companyname';
import Box from '@mui/material/Box';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Grid from '@mui/material/Grid';
import Navigator from '../landingpage/slider/new';
import '../search/search.css'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import MagnifiedImage from './MagnifiedImage';
import ReactImageMagnify from 'react-image-magnify';
// import ImageMagnifier from './imagemagnifier,js';
// import ImageMagnifier1 from './imagemagnifier';


function ImageMagnifier({
  src,
  width,
  height,
  magnifierHeight = 200,
  magnifieWidth = 200,
  zoomLevel = 2
}: {
  src: string;
  width?: string;
  height?: string;
  magnifierHeight?: number;
  magnifieWidth?: number;
  zoomLevel?: number;
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        height: height,
        width: width
      }}
    >
      <img
        src={src}
        style={{ height: height, width: width }}
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          // calculate cursor position on the image
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          // close magnifier
          setShowMagnifier(false);
        }}
        alt={"img"}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",

          // prevent maginier blocks the mousemove event of img
          pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1", // reduce opacity so you can verify position
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",

          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          //calculete position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
        }}
      ></div>
    </div>
  );
}

const Addcart = () => {
    const mcc = useContext(Companyname);
  const{setMcname}=useContext(Companyname)

  const[loading,setLoading]=useState(false)
    const[loading1,setLoading1]=useState(false)
    const[list,setList]=useState([])
    const[list1,setList1]=useState([])
    const[list2,setList2]=useState([])
    const[listvideo,setListvideo]=useState([])
    const[dimage,setDimage]=useState('')
    const[isno,setIsno]=useState(0)
    const[smenu,setsmenu]=useState(false)
    const [data, setData] = useState([]);
    const [qyt, setQyt]=useState(1)
    const[cartitems,setCartitems,catRef]=useState([])
    const[size,setSize]=useState('')
    const[counter,setCounter,counterRef]=useState(0)
    const[dis,setDis]=useState(false)
    const[dissize,setdissize]=useState(false)
    const[sizedisplay,setsizedisplay]=useState("")



    useEffect(() => {
    //     if (dis==false){
    //     setInterval(() => {
    //         setDis(true)
    //         handlecart()
    //     }, 1000);
    // }
    handlecart()
    }, []);  

    const handlesize=(e)=>{
  setSize(e.target.value)
    }
    
    

  function loaddata(sno) {
    var jarray = [];
    //event.preventDefault();
    jarray.push({ sno: sno, mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsno.php'
    var b = false;

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        if (response.data[0]["status"] == 1) {
            
          setData(response.data);

        }
      });
  }
  const fetchDatadesc = async (sno) =>{
    setLoading(false);
  var jarray=[]
  jarray.push({sno: sno, key: '101w021'});
  const mydata = JSON.stringify(jarray);
  const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productdesc.php';
    try {
      const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
      setList(response);
      console.log(response)
    } catch (error) {
      console.error(error.message);
    }
    setLoading(true);
  }

  const fetchDataimage1 = async (sno) =>{
    setLoading1(false);
  var jarray=[]
  jarray.push({sno: sno, key: '101w021'});
  const mydata = JSON.stringify(jarray);
  const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productimage.php';
  
    try {
      const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
      
      setList1(response);
      
      setDimage(response[0]['image'])
    } catch (error) {
      console.error(error.message);
    }
    setLoading1(true);
  }

  const fetchDatavideo = async (sno) =>{
    var jarray=[]
    jarray.push({sno: sno, key: '101w021'});
    const mydata = JSON.stringify(jarray);
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productvideo.php';
    
      try {
        const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
        
        setListvideo(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading1(true);
    }
    const fetchDataimage = async (sno) =>{
        var jarray=[]
        jarray.push({sno: sno, key: '101w021'});
        const mydata = JSON.stringify(jarray);
        const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productimage.php';
        
          try {
            const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
            
            
            
            
            Promise.resolve()
                .then(() => { setList1(response);})
                .then(() => { setDimage(response[0]['image'])})
          } catch (error) {
            console.error(error.message);
          }
      }
      const fetchDatasize = async (sno) =>{
        var jarray=[]
        jarray.push({sno: sno, key: '101w021'});
        const mydata = JSON.stringify(jarray);
        const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productsize.php';
        
          try {
            const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
            
            setList2(response);
            let m= "Size " + response[0].size
            setsizedisplay(m)
            console.log(response)
          } catch (error) {
            console.error(error.message);
          }
      }      

  const handlecart=()=>{
   
        //view product details
        //const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: mcc.mcname['search'], login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno: mcc.mcname['sno'],price: mcc.mcname['price'],pname:'',checkout:false,size:''}
        //setMcname(mv)
        fetchDatadesc(mcc.mcname['sno']);
        fetchDataimage(mcc.mcname['sno']);
        fetchDatasize(mcc.mcname['sno']);
        fetchDatavideo(mcc.mcname['sno']);
        loaddata(mcc.mcname['sno']);
        setDis(true)
        //alert('ddd')

  }

  const handleaddcart=(sno,price,pname,sprice)=>{
   
    
    var a=price
    a=a.replace(/\,/g,'')
    price=Number(a)
    var t=counter+1;
    const tcost=mcc.mcname['price'] + (price * qyt)
    let mcart={sno:sno, qyt:qyt, size:size,price:price,pname:pname, sprice: sprice}
   setCartitems([...cartitems, mcart]);
   var mm=[...mcc.mcname['cartlist'], mcart]
   var nitem=mm.length
   var t=0
    mcc.mcname['cartlist'].forEach(function (item) {
      t=t+item.price
      
    });
    t=t+tcost
   const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: true, status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: nitem, cartlist: mm, sno:'',price:7,checkout:false,size:'', lat: 0.0, lng: 0.0,tcost:t}
   setMcname(mv)

 alert("Product added to Cart")
   
   
      
  }
  const handleqyt=(e)=>{
    setQyt(e.target.value)
    
  }

  const handleback=()=>{
    const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
        setMcname(mv)
  
  }
  
  

      const handlenewimage=(image)=>{
        setDimage(image)
        
      }
  
  return (
    <section className="py-12 sm:py-5 bg-white"> 
    <div className="container mx-auto px-4 bg-white-600">
      <nav className="flex">
        <ol role="list" className="flex items-center">
          <li className="text-left">
            <div className="-m-1">
            <Button className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800" variant="contained" onClick={handleback}>Back</Button>
              
            </div>
          </li>
  
          
        </ol>
      </nav>
  
      <div className="lg:col-gap-12 xl:col-gap-16 mt-2 grid grid-cols-1 gap-12 lg:mt-4 lg:grid-cols-5 lg:gap-16">
        <div className="lg:col-span-3 lg:row-end-1">
          <div className="lg:flex lg:items-start">
          {/* {data.map((item) => ( */}
            <div className="lg:order-2 lg:ml-5">
              <div className="flex justify-center items-center h-screen">
      
      <ImageMagnifier
        width={"400px"}
        src={dimage}
      />
      
    </div>
              {/* <div className="max-w-xl overflow-hidden rounded-lg">
                <img className="h-full w-full max-w-full object-cover" src={item.image} alt="" />
              </div> */}
            </div>
          {/* ))} */}
  
            <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
              <div className="flex flex-row items-start lg:flex-col">
              {list1.map((index) =>(
                <button onClick={()=>handlenewimage(index.image)} type="button" className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-900 text-center">
                  <img className="h-full w-full object-cover" src={index.image} alt="" />
                </button>
              ))}
                
              </div>
            </div>
          </div>
        </div>
        {data.map((item) => (
  
        <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
          
          <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">{item.pname}</h1>
          <p>{item.desc}</p>
  
          <div className="mt-5 flex items-center">
            <div className="flex items-center">
              <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
              </svg>
              <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
              </svg>
              <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
              </svg>
              <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
              </svg>
              <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
              </svg>
            </div>
            <p className="ml-2 text-sm font-medium text-gray-500"> Reviews</p>
          </div>
          {sizedisplay.length>5 &&
          <div>
          
          <div className="mt-5 flex flex-col items-center justify-between space-y-4 border-t  py-4 sm:flex-row sm:space-y-0">
            <label className="">
            <TextField  label={sizedisplay} variant="outlined"  value={size} onChange={handlesize}/>
             
            </label>
            
          </div>
          </div>}
  
          <div className="mt-5 flex flex-col items-center justify-between space-y-4 border-t  py-4 sm:flex-row sm:space-y-0">
          <TextField  label="Quantity" variant="outlined" type="number"  value={qyt} onChange={handleqyt}/>
          </div>
          <div className="mt-5 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
            <div className="flex items-end">
              <h1 className="text-3xl font-bold">KES {item.price}</h1>
            </div>
            
  
            <button onClick={(()=>handleaddcart(item.sno,item.price,item.pname,item.sprice))} type="button" className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-gray-900 bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
              </svg>
              Add to cart
            </button>
          </div>
  
          <ul className="mt-8 space-y-2">
            <li className="flex items-center text-left text-sm font-medium text-gray-600">
              <svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" className=""></path>
              </svg>
              Free Delivery
            </li>
  
            <li className="flex items-center text-left text-sm font-medium text-gray-600">
              <svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" className=""></path>
              </svg>
              Pay on delivery
            </li>
          </ul>
          <div className="mt-5 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
            <div className="flex items-end">
              <h1 className=" "> {item.pdesc}</h1>
            </div>
          </div>
        </div>
        ))}

        
  
        <div className="lg:col-span-3 text-left">
          <div className="border-b border-gray-300">
            <nav className="flex gap-4">
              <a href="#" title="" className="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"> Description {}</a>
  
              
            </nav>
          </div>
          {list.map((index) =>(
          <div className="mt-1 flow-root sm:mt-1">
            
            <p >{index.desc}</p>
          </div>
          ))}
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Addcart
