import React from "react";

import * as MuiIcons from '@material-ui/icons'

export default ({ iconName }) => {

const Icon = MuiIcons[iconName]

return (<Icon />)

}