import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import '../css/style1.css'
import './files/my.css'
import Companyname from './companyname';
import axios from 'axios';
import ComponentWithIcon from "../landingpage/slider/ComponentWithIcon";
import {  makeStyles } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DropdownSearchTextField from './dropdowntextfield';
import Drawer1 from './drawer';
import DrawerMenu from './drawermenu';

const baStyle=makeStyles({
  header:{
    backgroundColor: "white",
    color: "black",

  }
})


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(10),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  right: '0',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

export default function Contact(props) {
  const mcc = React.useContext(Companyname);
    const{setMcname}=React.useContext(Companyname)

    const [data, setData] = React.useState([]);
  const[subcat,setSubcat] =React.useState([])
  const [cselect,setCselect]=React.useState('')
  const[dis,setDis]=React.useState(true)
  const[tcost,setTcost]=React.useState(0)
  const [wsearch,setWsearch]=React.useState('')


  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setSubmenuOpen(false);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  const handleSearch1=()=>{
   
    if(wsearch.length>1){
      fetchData();

    }
    
  }

  const fetchData = async () =>{
    // const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
    // setMcname(mv)
    
    var jarray=[]
    jarray.push({pname: wsearch, key: '101w021'});
    const mydata = JSON.stringify(jarray);
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib2/productlistdisplay1.php';
      try {
        const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
        //set responnse data
       const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0,menu:false}
       setMcname(mv)
      } catch (error) {
        console.error(error.message);
      }
      
    }
  
    
    const handleSearch=(e)=>{
      
      if( e.key=='Enter'){
        //reload data
        fetchData();
  
      }
      else
      {
        //const mv={ mobno: '', status1: true, status2: true, search: false, login: false, signup: false, list: []}
        //setMcname(mv)
        
      }
    
    }





  React.useEffect(() => {
    loaddata();
  }, []);

    const handleCheckout=()=>{
      if (Number(mcc.mcname['cart'])>0){
     const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:true,corder:false,lat: 0.0, lng: 0.0,menu:false,tcost:mcc.mcname['tcost']}
         setMcname(mv)
      }
       else
       alert('Your basket is empty')
    }

    const fetchDatasub = async (cat) =>{
    
      var jarray=[]
      jarray.push({category: cat, type: "category", key: '101w021'});
      const mydata = JSON.stringify(jarray);
      const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsubcategory.php';
        try {
          
          const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
          //set responnse datasearch
          
          const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
          setMcname(mv)
          console.log("products")
          console.log(response)
          
        } catch (error) {
          console.error(error.message);
        }
        
      }

      function subcategory(cat){
        var jarray = [];
        //event.preventDefault();
        jarray.push({ cat: cat, mkey: 1090 });
        const mydata = JSON.stringify(jarray);
        //send data online
        const url =
        'https://www.storepoaenterprise.com/ecomerce/lib3/subcategorylist.php'  
        
        axios
          .post(url, mydata, { headers: { "Content-Type": "application/json" } })
          .then(function (response) {
            //check response
            if (response.data[0]["status"] === 1) {
              setSubcat(response.data);
              console.log(response.data)
              
            }
          });
    
     
      }

      function loaddata() {
        var jarray = [];
    
        //event.preventDefault();
        //jarray.push({ pcategory: "", mkey: 1090 });
        const mydata = JSON.stringify(jarray);
        //send data online
        const url =
        'https://www.storepoaenterprise.com/ecomerce/lib3/categorylist.php' 
    
        axios
          .post(url, mydata, { headers: { "Content-Type": "application/json" } })
          .then(function (response) {
            //check response
    
            if (response.data[0]["status"] === 1) {
              setData(response.data);
              //console.log(data);
            }
          });
      }

  return (
    <header className="bg-white">
      
      <Drawer1 />
      <div className="container mx-auto px-2 py-8 flex items-center">
      
      
        
        {/* Logo */}
        {/* <div className="mr-auto md:w-48 flex-shrink-0">
          <img
            className="h-8 md:h-10"
            src="https://i.ibb.co/98pHdFq/2021-10-27-15h51-15.png"
            alt=""
          />
        </div> */}
        <div className="ml-5 md:w-48 hidden sm:flex flex-col place-items-end ">
          <span className="font-bold md:text-xl">Free Delivery</span>
          <span className="font-semibold text-sm text-gray-400">Pay on delivery</span>
        </div>

        {/* {data.map((value, index) => (
            <option className="box flex cursor-pointer" key={index}>
              <span
                
                className="nn"
              >
                <ComponentWithIcon
                  iconName={value.icon}
                  className="text-yellow-500"
                />
                {value.category}
              </span>
            </option>
          ))} */}

        {/* Search */}
        {/* <div className="w-full max-w-xs xl:max-w-lg 2xl:max-w-2xl bg-gray-100 rounded-md  xl:flex items-center ml-5">
        
        <Search sx={{ borderColor: 'primary.main' }}>
          <SearchIconWrapper className='button-34' style={{cursor: "pointer"}} onClick={handleSearch1}>
              
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for Product"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e=>setWsearch(e.target.value)}
              onKeyPress={e=>handleSearch(e)}
            />
            
          </Search>
          <svg
            className="ml-auto h-5 px-4 text-gray-500"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="search"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            onClick={fetchData}
          >
            <path
              fill="currentColor"
              d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
            ></path>
          </svg>
        </div> */}

<div class="w-full max-w-xs xl:max-w-lg 2xl:max-w-2xl rounded-md xl:flex items-center ml-5">
  <div class="relative w-full">
    <input
      type="text"
      class="w-full bg-gray-200 rounded-full px-4 py-2 pl-10 focus:outline-none focus:ring focus:border-blue-300"
      placeholder="Search products..."
      onChange={e=>setWsearch(e.target.value)}
      onKeyPress={e=>handleSearch(e)}
    />
    <div class="absolute top-0 left-0 mt-2 ml-3" onClick={fetchData}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-4.35-4.35a8 8 0 10-1.414-1.414L21 21zm-8-2a6 6 0 111.414-1.414A6 6 0 0113 19z"
        />
      </svg>
    </div>
  </div>
</div>




        {/* Phone number */}
        <div className="ml-auto md:w-48 hidden sm:flex flex-col place-items-end">
          <span className="font-bold md:text-xl">+254769343028</span>
          <span className="font-semibold text-sm text-gray-400">Support 24/7</span>
        </div>

        {/* Buttons */}
        <nav className="contents">
          <ul className="ml-4 xl:w-48 flex items-center justify-end">
          
            <li className="ml-2 lg:ml-4 relative inline-block">
              <a className="" href="#">
                <div className="absolute -top-1 right-0 z-10 bg-yellow-400 text-xs font-bold px-1 py-0.5 rounded-sm">0</div>
                <svg
                  className="h-9 lg:h-10 p-2 text-gray-500"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="heart"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="ml-2 lg:ml-4 relative inline-block" onClick={handleCheckout}>
              <a className="" href="#">
                <div className="absolute -top-1 right-0 z-10 bg-yellow-400 text-xs font-bold px-1 py-0.5 rounded-sm">{mcc.mcname['cart']}</div>
                <svg
                  className="h-9 lg:h-10 p-2 text-gray-500"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="shopping-cart"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </nav>

        {/* Cart count */}
        <div className="ml-4 hidden sm:flex flex-col font-bold">
          <span className="text-xs text-gray-400">Your Cart</span>
          <span>KES {mcc.mcname['tcost']}</span>
        </div>
      </div>

      <hr />
    </header>

  );
}