import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Paper, Typography } from '@mui/material';
import Image1 from './images/discount2.jpg';
import Image2 from './images/discount1.jpg';

const styles = {
    paperContainer: {
        backgroundImage: `url(${Image1})`,
        '&:hover': {
              opacity: [0.9, 0.8, 0.7],
            },
    },
    paperContainer2: {
        backgroundImage: `url(${Image2})`,
        '&:hover': {
              opacity: [0.9, 0.8, 0.7],
            },
    }
};
export default function Rate() {
  return (
    <Grid item xs={12} style={{marginTop:3}}>
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={6} sm={6}>
    <Paper
     style={styles.paperContainer}
      sx={{
        width: "100%",
        height: 400

        // background: ``,
        // '&:hover': {
        //   opacity: [0.9, 0.8, 0.7],
        // },
      }}
    />
    
    </Grid>
    <Grid item  xs={5} sm={5}>
    <Paper
     style={styles.paperContainer2}
      sx={{
        width: "100%",
        height: 400
        // backgroundColor: 'primary.dark',
        // '&:hover': {
        //   backgroundColor: 'primary.main',
        //   opacity: [0.9, 0.8, 0.7],
        // },
      }}
    />
    </Grid>
    </Grid>
    </Grid>
   
  );
}