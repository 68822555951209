import React, {useState,useContext} from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { Box, TextField, Container, Grid, Button, Divider} from '@mui/material';
import { Paper } from '@material-ui/core';
import validator from 'validator';
import Login1 from './login1'
import Companyname from './companyname';
import './my.css';



export default function User(props) {
  const{setMcname}=useContext(Companyname)
const[mobno,setMobno]=useState('')
const[vmobno,setVmobno]=useState(false)
const[mmobno,setMmobno]=useState('')

const[fname,setFname]=useState('')
const[vfname,setVfname]=useState(false)
const[mfname,setMfname]=useState('')

const[lname,setLname]=useState('')
const[vlname,setVlname]=useState(false)
const[mlname,setMlname]=useState('')

const[pwd,setpwd]=useState('')
const[vpwd,setVpwd]=useState(false)
const[mpwd,setMpwd]=useState('')

const[pwd1,setpwd1]=useState('')
const[vpwd1,setVpwd1]=useState(false)
const[mpwd1,setMpwd1]=useState('')

const[name,setname]=useState('')
const[vname,setVname]=useState(false)
const[mname,setMname]=useState('')

const[email,setemail]=useState('')
const[vemail,setVemail]=useState(false)
const[memail,setMemail]=useState('')

const handleFname=(e)=>{
setFname(e.target.value)
var f=e.target.value

      if(f.length>1){
        setVfname(true)
        setMfname('')
      }
      else{
        setVfname(false)
        setMfname('Enter a Valid name')
      }

}
const handleLname=(e)=>{
  setLname(e.target.value)
  var f=e.target.value
        if(f.length>1){
          setVlname(true)
          setMlname('')
        }
        else{
          setVlname(false)
          setMlname('Enter a Valid name')
        }
  
  }
const handleMobno=(e)=>{
setMobno(e.target.value)
var f=e.target.value
if (validator.isMobilePhone(f) && ((f.length==10 && f[0]=="0") || (f.length==14 && f[0]=="+"))){
  setVmobno(true)
  setMmobno('')
}
else{
  setVmobno(false)
  setMmobno('Enter a valid mobile number')
}
}
const handlePwd=(e)=>{
  setpwd(e.target.value)
  var f=e.target.value
if (validator.isStrongPassword(f)){
  setVpwd(true)
  setMpwd('')
}
else{
  setVpwd(false)
  setMpwd('Enter strong password 8char, lowe & upper, speceial char')
}

}
const handlePwd1=(e)=>{
setpwd1(e.target.value)
var f=e.target.value
if (f==pwd){
  setVpwd1(true)
  setMpwd1('')
}
else{
  setVpwd1(false)
  setMpwd1('Password Not matching')
}

}
const handleEmail=(e)=>{
setemail(e.target.value)
var f=e.target.value
if (validator.isEmail(f)){
  setVemail(true)
  setMemail('')
}
else{
  setVemail(false)
  setMemail('Enter a valid email address')
}
}

const handleSave=()=>{
  //mobno,cname,pwd,name,email
  
  if (vmobno==true && vfname==true && vpwd==true && vpwd1==true && vlname==true && vemail==true ){
    var jarray=[]
    //event.preventDefault();

   
      jarray.push({fname: fname, lname: lname, pwd: pwd, mobno: mobno, email: email, mkey: '1090'});
      
      const mydata = JSON.stringify(jarray);
    //send data online
     const url = 'https://www.storepoaenterprise.com/ecomerce/lib2/usersave.php';
     
     
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
     //check response
    if (response.data[0]['status']==1){
     alert('You have sucessfully signed up login to continue')
     const mv={ mobno: '', status1: false, status2: true, search: '', login: true, signup: false}
          
    setMcname(mv)
    
    }
    else{
      alert('Invalid credentials')
    }
      
  })
  
}
}
const handleRow=()=>{

}

const handleClose=()=>{
  const mv={ mobno: '', status1: false, status2: true, search: '', login: true, signup: false}
          
    setMcname(mv)
}

  return (
      <div>
        <h2>Sign Up</h2>
        <Divider />
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      
      <TextField id="standard-basic" label="First Name" variant="standard" helperText={mfname} onChange={handleFname}/>
      </Box>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      
      <TextField id="standard-basic" label="Last Name" variant="standard" helperText={mlname} onChange={handleLname}/>
      </Box>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Mobile Number" variant="standard" helperText={mmobno} onChange={handleMobno}/>
      </Box>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Email" variant="standard" helperText={memail} onChange={handleEmail}/>
    </Box>
    <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
      <TextField id="standard-basic" label="Password" variant="standard" inputProps={{  type: 'password'}} helperText={mpwd} onChange={handlePwd}/>
      </Box>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Confirm Password" variant="standard" inputProps={{  type: 'password'}} helperText={mpwd1} onChange={handlePwd1}/>
    </Box>
    <Divider />
    <br/>
<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='mypaper'>
<Grid item xs={6}>
  <Button variant="contained" onClick={handleSave}>Add</Button>
</Grid>
<Grid item xs={6}>
  <Button variant="contained" onClick={handleClose}>Close</Button>
</Grid>

</Grid>
</div>
  );
}