import * as React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import {  makeStyles } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './my.css'
import Companyname from './companyname';
import Login1 from './login1';
import { Grid } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import logo from './kolumall.png'
import MailIcon from '@mui/icons-material/Mail';
import Drawer1 from './drawer';

const baStyle=makeStyles({
  header:{
    backgroundColor: "white",
    color: "black",

  }
})


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(10),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  right: '0',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

export default function Header2(props) {
 const classes=baStyle();
    const mcc = React.useContext(Companyname);
    const{setMcname}=React.useContext(Companyname)
    const [state, setState] = React.useState({
        left: false,
        
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

      const handleSendinvite=()=>{

      }

      const handleChangepwd=()=>{

      }
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
         
        <Divider />
          <List>
              
              <ListItem button onClick={handleProfile}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button onClick={handleChangepwd}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary="Change Password" />
              </ListItem>
              <ListItem button onClick={handleSendinvite}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary="Send Invite" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            
          </List>
          <Divider />
        </Box>
      );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [wsearch,setWsearch]=React.useState('')

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenulogout =()=>{
    setAnchorEl(null);
    handleMobileMenuClose();
    const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: false, status3: false, search:  false, login: false, signup: false, list: [], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'],sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu:false}
          
    setMcname(mv)
  }
  const handleMenulogin=()=>{
    ReactDOM.render(
      <React.StrictMode>
        <Login1 />
      </React.StrictMode>,
      document.getElementById('mbody')
    );

  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    handleProfileMenuOpen(event)
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {mcc.mcname['status1'] ?
      <div>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Change Password</MenuItem>
      <MenuItem onClick={handleMenulogout}>Log out</MenuItem>
      </div>
      :
      <MenuItem onClick={handleMenulogin}>Log in</MenuItem>}
    </Menu>
  );

  

  const mobileMenuId = 'primary-search-account-menu-mobile';
  
 const handleTab=()=>{
     
 }
 
  
  const handleLogout=()=>{
    const mv={ mobno: '', status1: false, status2: false, status3: false, search: false, login: false, signup: false, list: [], cart: 0, cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu:false}
          
          setMcname(mv)
    
  }

  const handleProfile=()=>{

  }
  const handleSearch1=()=>{
   
    if(wsearch.length>1){
      fetchData();

    }
    
  }

  const fetchData = async () =>{
    
  var jarray=[]
  jarray.push({pname: wsearch, key: '101w021'});
  const mydata = JSON.stringify(jarray);
  const url = 'https://www.storepoaenterprise.com/ecomerce/lib2/productlistdisplay1.php';
    try {
      const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
      //set responnse data
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0,menu:false}
      setMcname(mv)
    } catch (error) {
      console.error(error.message);
    }
    
  }

  
  const handleSearch=(e)=>{
    if( e.key=='Enter'){
      //reload data
      fetchData();

    }
    else
    {
      //const mv={ mobno: '', status1: true, status2: true, search: false, login: false, signup: false, list: []}
      //setMcname(mv)
      
    }
  
  }
  const handless=()=>{
    alert(mcc.mcname['status1'])
  }
  const handleProfileMenulogin=()=>{
    const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: true, status3: false, search: '', login: true, signup: false, list: [], cart: mcc.mcname['cart'], cartlist:mcc.mcname['cartlist'], sno:'',price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0,menu:false}
          
    setMcname(mv)
  }
 const handleCheckout=()=>{
   if (Number(mcc.mcname['cart'])>0){
  const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:true,corder:false,lat: 0.0, lng: 0.0,menu:false}
      setMcname(mv)
   }
    else
    alert('Your basket is empty')
 }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky"  className='grad'>
          <Drawer1 />
          
        <Toolbar>
           

          <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
          <Typography
            //variant="h6"
            noWrap
            component="div"
            //sx={{ display: { xs: 'none', sm: 'block' } }}
            //color={"orange"}
            
          >
          
           {/* <img src={logo} width="150" style={{margin:3}}/> */}
           <h1 class="text-white-600 text-3xl font-bold drop-shadow-lg">Store Poa</h1>
           
          </Typography>
          </Grid>
          <Grid item xs={7} md={6} style={{margin: 5}}>
          <Search sx={{ borderColor: 'primary.main' }}>
          <SearchIconWrapper className='button-34' style={{cursor: "pointer"}} onClick={handleSearch1}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for Product"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e=>setWsearch(e.target.value)}
              onKeyPress={e=>handleSearch(e)}
            />
            
          </Search>
          

          </Grid>
          
          <Grid item xs={2} md={3} style={{margin: 5}}></Grid>
          
          
          
          
          <div>
           <Box sx={{ display: { xs: 'flex', md: 'flex' } }} >
            
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleCheckout}
                color="inherit"
                className='button-35'
              >
                <Badge badgeContent={mcc.mcname['cart']} color="success" >
              <ShoppingCartCheckoutIcon style={{ backgroundColor: "orange", color: "green"}}/>
              </Badge>

            </IconButton>
            
          </Box>
         
          
          </div>

         
         
          </Grid>
        </Toolbar>
      </AppBar>
      
      {renderMenu}
    </Box>
  );
}