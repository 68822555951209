import { NoMeetingRoom } from "@material-ui/icons";
import React, {useContext, useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import Companyname from "./navbar/companyname";
import Login1 from "./navbar/login1";
import Landingpage from "./landingpage/landingpage";
import Searchresults from "./search/searchresults";
// import './css/style1.css';
import Product from "./ProductPage/Product";
import Map4 from "./maps/map4.tsx";
import Checkout from "./checkout/checkout";
import Addcart from "./cart/addcart";




const Home = ()=>{
  const[mna,setmna]=useState('ppp');
  const mcc = useContext(Companyname);
  return(
<Container id="mbody" className="center">
  {mcc.mcname['checkout'] ?
      <div>
        <Checkout />
      </div>
      :
 <div>
{mcc.mcname['search'] ?
<div>
{mcc.mcname['status1'] ?
          <Addcart />
          :
  <Searchresults />}
</div>
:
  <div>
{ mcc.mcname['login'] ?
      <Login1 />
        :
        <div>
          {mcc.mcname['status1'] ?
          <Addcart />
          :
          <Landingpage />}
       </div>
  }
  </div>}
  </div>}
</Container>
  );
}
export default Home