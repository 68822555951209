import * as React from "react";
import { useRef,useEffect,useContext} from "react";
import useState from 'react-usestateref'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import './styles.css'
import Companyname from "../navbar/companyname";
import { Button } from "@mui/material";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
  import TextField from '@mui/material/TextField';
  import { Card, CardContent, Typography } from '@mui/material';
  import axios from "axios";


  

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

let service: google.maps.places.PlacesService;
let infowindow: google.maps.InfoWindow;

const Map6: React.VFC = () => {
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(16); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: -1.286389,
    lng: 36.817223,
  });

  const mcc = React.useContext(Companyname);
  const{setMcname}=React.useContext(Companyname)

  //curent loc
 var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  
  function success(pos) {
    var crd = pos.coords;
    var accuracy=crd.accuracy
    setCenter({ ...center, lat: Number(crd.latitude) })
    setCenter({ ...center, lng: Number(crd.longitude) })
    const mv={ mobno: mcc.mcname['mobno'], nature: mcc.mcname['nature'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], search: false, login: false, signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], sno: '',price:0,pname:'',checkout:true, crd: crd,corder: true,email: mcc.mcname['email'],pwd: mcc.mcname['pwd']}
    setMcname(mv)

    
  }
  
  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  /*
  const handleloc=()=>{
  navigator.geolocation.getCurrentPosition(success, error, options);
  } 
  React.useEffect(()=>{
    handleloc()
      },[]
    
      );
*/

function Getcode(){
    if(mcc.mcname['mobno'].length>9){
    var jarray=[]
    jarray.push({mobno: mcc.mcname['mobno'], key: '101w021'});
  
    const mydata = JSON.stringify(jarray);
    //send data online
    
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/getcode.php';
    axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    
        
    })
    .catch(function (error) {
        // handle error
    })
    .then(function () {
        // always executed
    });}
    else{
        alert('Invalid Entry')
    }
}



//this runs when you click on the map
  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    var co=e.latLng.toJSON()
    setCenter(e.latLng)
    setClicks([e.latLng!]);
    let bs=false
    if(mcc.mcname['mapsource']=='beseller'){
     
    bs=true
    const cdetail={cname:mcc.mcname['crd']['cname'],cperson:mcc.mcname['crd']['cperson'],vstate:false}
    const mv={ mobno: mcc.mcname['mobno'], nature: mcc.mcname['nature'], status1: false, cname: '', status2: false, search: false, login: false, crd: cdetail, lat: Number(co['lat']),lng: Number(co['lng']), map:false, beseller:bs,mapsource:'',email: mcc.mcname['email'],pwd: mcc.mcname['pwd']}
     setMcname(mv)
        Getcode()
    }
    else{
    const mv={ mobno: '', nature: '', status1: false, cname: '', status2: false, search: false, login: true, crd: null, lat: Number(co['lat']),lng: Number(co['lng']), map:false, beseller:bs,mapsource:'',email: mcc.mcname['email'],pwd: mcc.mcname['pwd']}
     setMcname(mv)
    }
  };

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };


  //auto complete
  const[address,setAddress]=React.useState('')
  //const[latlog,setLatlog]=React.useState(null)
  const[latlog,setLatlog,latref]=useState(null)
  
   
  const handleChange = () => {
    // this.setState({ address });
   };
  
   const Updatevar=(latLng)=>{
       setCenter(latLng)
       
       const mv={ mobno: mcc.mcname['mobno'], nature: mcc.mcname['nature'], status1: false, cname: mcc.mcname['cname'], status2: false, search: false, login: false, crd: mcc.mcname['crd'], lat: latLng.lat,lng: latLng.lng, map:true, beseller:mcc.mcname['beseller'],mapsource:mcc.mcname['mapsource'],email: mcc.mcname['email'],pwd: mcc.mcname['pwd']}
       setMcname(mv)
     
   }
 
   const handleSel=(e)=>{
     geocodeByAddress(e)
     .then(results => getLatLng(results[0]))
     .then(latLng => setLatlog(latLng))
     .then(latLng => Updatevar(latref.current))
     .catch(error => console.error('Error', error));
   }
  

  return (
      <div className="box">
        {/* {latlog} */}
         <PlacesAutocomplete
        value={address}
        onChange={setAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
              <TextField
              id="outlined-basic" 
              label="Search Places ..." 
              variant="outlined" 
              style={{backgroundColor: "white"}}
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              className="map2"
              />
            
            <Card className="autocomplete-dropdown-container map2" sx={{ maxWidth: 405, left: 300 }} >
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <CardContent
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <Typography onClick={(()=>handleSel(suggestion.description))}>{suggestion.description}</Typography>
                  </CardContent>
                );
              })}
            </Card>
          </div>
        )}
        
      </PlacesAutocomplete>
    <div className="showmap">
      <Wrapper apiKey={"AIzaSyDzBzxl_Co2GNYHCTDcPmRHKC9sAyOO1uU"} render={render} >
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          
        >
          {clicks.map((latLng, i) => (
              
            <Marker key={i} position={latLng} />
          ))}
        </Map>
      </Wrapper>
    </div>
    </div>
  );
};
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  //find place
  const handleFindplace=()=>{
    
  const request = {
    query: "Museum of Contemporary Art Australia",
    fields: ["name", "geometry"],
  };

  service = new google.maps.places.PlacesService(map);

  service.findPlaceFromQuery(
        request,
    (
      results: google.maps.places.PlaceResult[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i]);
        }

        map.setCenter(results[0].geometry!.location!);
      }
    }
  );

  }

function createMarker(place: google.maps.places.PlaceResult) {
 
  if (!place.geometry || !place.geometry.location) return;

  const marker = new google.maps.Marker({
    map,
    position: place.geometry.location,
  });

  google.maps.event.addListener(marker, "click", () => {
    infowindow.setContent(place.name || "");
    infowindow.open(map);
  });
}



  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} style={{ height: '80vh', width: '100%', left: "-10vh" }}></div>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
      
    </>
  );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
/*
window.addEventListener("DOMContentLoaded", () => {
  ReactDom.render(<App />, document.getElementById("root"));
});

export {};
*/



export default Map6