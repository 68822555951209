import React, {useContext,useState,useEffect} from 'react'
import Map4 from '../maps/map4.tsx'
import Companyname from '../navbar/companyname';
import "../css/style1.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, Button, TextField, Box, Grid } from '@mui/material';
import Map1 from '../maps/Map1';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, sendSignInLinkToEmail, signInWithPhoneNumber } from 'firebase/auth';
import Map9 from '../maps/map9.js';
//import PhoneAuth from '../sms/phoneauth.js';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Checkout = () => {
  const[mna,setmna]=useState('ppp');
  const mcc = useContext(Companyname);
  const [vmap,setVmap]=useState(true)
  const[tcost,setTcost]=useState(0)
  const[mobno,setmobno]=useState('')
  const[mmobno,setmmobno]=useState('')
  const[name,setnamme]=useState('')
  const[email,setemail]=useState('')
  const[ccode,setccode]=useState('')
  const[pvno,setpvno]=useState('')
  const[step1,setstep1]=useState(true)
  const[cdetails,setcdetails]=useState(true)
  const[reg,setreg]=useState(true)

  const{setMcname}=useContext(Companyname)


  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [myauth, setmyauth] = useState(null);
  const [confirmationResult, setconfirmationResult] = useState(null);

  // Initialize Firebase with your Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyBCpys7tvXD06cA7dGiXNjsPNVV7jeyHZ8",
    authDomain: "otp-sms-526c6.firebaseapp.com",
    projectId: "otp-sms-526c6",
    storageBucket: "otp-sms-526c6.appspot.com",
    messagingSenderId: "729272905219",
    appId: "1:729272905219:web:f1438bdbd057d361e5e836"
};

const verifyRecaptcha = async () => {
const firebaseApp = await initializeApp(firebaseConfig);
const auth = await getAuth(firebaseApp);
setmyauth(auth)
const rcpt=await new RecaptchaVerifier(auth, 'recaptcha-container', {
    size: 'invisible',
  callback: (response) => {
    // reCAPTCHA solved, allow signInWithPhoneNumber
    //console.log("callback")
  },
 });

setRecaptchaVerifier(rcpt)
}

const sendOTP = async() => {
  //   Send the OTP to the user's phone number
  try {
      const confirmationResult2 = await signInWithPhoneNumber(myauth, mobno, recaptchaVerifier);
      setconfirmationResult(confirmationResult2)
      setstep1(false)
      findcustomer()
      // You can proceed with further actions after successful authentication.
    } catch (error) {
      // Handle the error here
      console.error('Error signing in with phone number:', error);
      // You can also update the error state or display an error message to the user.
    }
  // const confirmationResult2 = await signInWithPhoneNumber(myauth, phoneNumber, recaptchaVerifier)
  
      
  };
  
  const verifyOTP = async() =>{
      try {
      const credential = await confirmationResult.confirm(ccode);
      setcdetails(false)
        const mv={ mobno: mobno, status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: false, signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0, price:0,checkout: mcc.mcname['checkout'],lat: 0.0, lng: 0.0}
      setMcname(mv)
      console.log(credential)
  } catch (error) {
      // Handle the error here
      console.error('Error signing in with phone number:', error);
      // You can also update the error state or display an error message to the user.
    }
  
  }

  const handlemobile=()=>{
    
    if (isPossiblePhoneNumber(mobno)){
      sendOTP()
      
      
      }
      else{
        alert('Enter valid Mobile No.')
      }
    
  }

  

  useEffect(() => {
    var t=0
    mcc.mcname['cartlist'].forEach(function (item) {
      t=t+item.price
      
    });
    setTcost(t)
     verifyRecaptcha()
    
}, []);

function findcustomer() {
  var jarray = [];

  //event.preventDefault();
  jarray.push({ mobno: mobno, key: 1090 });
  const mydata = JSON.stringify(jarray);
  //send data online
  const url =
  'https://www.storepoaenterprise.com/ecomerce/lib3/customer.php'    
  var b = false;

  axios
    .post(url, mydata, { headers: { "Content-Type": "application/json" } })
    .then(function (response) {
      //check response
      
      if (response.data[0]["status"] == 1) {
        setreg(false)
        getcode()
        
      }
      if (response.data[0]["status"] == 0) {
        setreg(true)
      }
    });
}
function savecustomer() {
  var jarray = [];

  //event.preventDefault();
  jarray.push({ mobno: mobno, email: email, name: name, key: 1090 });
  const mydata = JSON.stringify(jarray);
  //send data online
  const url =
  'https://www.storepoaenterprise.com/ecomerce/lib3/customersave.php'    
  var b = false;

  axios
    .post(url, mydata, { headers: { "Content-Type": "application/json" } })
    .then(function (response) {
      //check response
      
      if (response.data[0]["status"] == 1) {
        alert('Information updated')
        setreg(false)
        getcode()
      }
     
    });
}

function getcode() {
  var jarray = [];

  //event.preventDefault();
  jarray.push({ mobno: mobno, key: 1090 });
  const mydata = JSON.stringify(jarray);
  //send data online
  const url =
  'https://www.storepoaenterprise.com/ecomerce/lib3/getcode.php'    
  var b = false;

  axios
    .post(url, mydata, { headers: { "Content-Type": "application/json" } })
    .then(function (response) {
      //check response
      
      if (response.data[0]["status"] == 1) {
        
        
      }
      
    });
}

function confirmcode() {
  var jarray = [];

  //event.preventDefault();
  jarray.push({ mobno: mobno, otp: ccode, key: 1090 });
  const mydata = JSON.stringify(jarray);
  //send data online
  const url =
  'https://www.storepoaenterprise.com/ecomerce/lib3/confirmcode.php'    
  var b = false;

  axios
    .post(url, mydata, { headers: { "Content-Type": "application/json" } })
    .then(function (response) {
      //check response
      
      if (response.data[0]["status"] == 1) {
        
        setcdetails(false)
        const mv={ mobno: mobno, status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: false, signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0, price:0,checkout: mcc.mcname['checkout'],lat: 0.0, lng: 0.0}
      setMcname(mv)
      }
      
    });
}

  const handlemap =()=>{
    //show map
    setVmap(false)
  }
 

 const handlereg=()=>{
  savecustomer()
}

const handlename=(e)=>{
setnamme(e.target.value)
}

const handleemail=(e)=>{
setemail(e.target.value)
}

const handleconfirm=()=>{
confirmcode()
}

const handlecode=(e)=>{
setccode(e.target.value)
}
const handleback=()=>{
  const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
      setMcname(mv)

}

  return (
    <Card className='item3 center shadow' style={{width:"90%"}}>
      <Grid container  spacing={1}>
      <Grid item xs={6} sm={4}>
      <Button variant="contained" onClick={handleback}>Back</Button>
      </Grid>
      <Grid item xs={6} sm={4}>
        <h2>Check out</h2>
        </Grid>
        </Grid>
        <Table  aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell align="right">Quantity</StyledTableCell>
            <StyledTableCell align="right">@price</StyledTableCell>
            <StyledTableCell align="right">Totals</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {mcc.mcname['cartlist'].map((item) => (
            
            <StyledTableRow>
            <StyledTableCell>{item.pname}</StyledTableCell>
            <StyledTableCell align="right">{item.qyt}</StyledTableCell>
            <StyledTableCell align="right">{item.price}</StyledTableCell>
            <StyledTableCell align="right">{item.price * item.qyt}</StyledTableCell>
          </StyledTableRow>
            

        ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <StyledTableCell>Item Totals</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right">{tcost}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Transport</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right">0</StyledTableCell>

          </TableRow>
          <TableRow>
            <StyledTableCell>Grand Totals</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right">{tcost }</StyledTableCell>
          </TableRow>
        </TableHead>
        </Table>
          {cdetails ?
        <Box
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      {step1 ?
      <div>
        <label>Mobile Number</label>
        <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="KE"
      placeholder="Enter phone number"
      value={mobno}
      onChange={setmobno}
      />
      <div id="recaptcha-container"></div>
      {/* <PhoneAuth /> */}
      <Button variant="contained" onClick={handlemobile} style={{margin:5}}>Next</Button>
      
      </div>
      :
      // <div>
      //   {reg ?
      // <div>
        
      // <TextField id="outlined-basic" label="Name" variant="outlined" value={name}  onChange={handlename}/>
      // <TextField id="outlined-basic" label="Email" variant="outlined" value={email} onChange={handleemail} style={{marginTop:5}}/>
      // <Button variant="contained" onClick={handlereg} style={{margin:5}}>Next</Button>
      // </div>
      // :
      <div>
      <TextField id="outlined-basic" label="Confirmation Code (sms)" variant="outlined" value={ccode} onChange={handlecode}/>
      <Button variant="contained" onClick={verifyOTP} style={{margin:5}}>Confirm</Button>
      </div>
      // }   
      // </div>}
    }
    </Box>
    :
    <div>
        {vmap ?
        
        <Button variant="contained" onClick={handlemap}>Select Drop Location</Button>
        :
        <Map9 />
        
        }
      </div>}
    </Card>
  )
}

export default Checkout
