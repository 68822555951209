import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { TextField, Autocomplete } from '@mui/material';
import { ImageOutlined } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import Companyname from "../navbar/companyname";
import axios from 'axios';

const Map9 = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [adresses, setadresses] = useState([]);
  const selection = React.useRef(true);
  const timer = React.useRef();
  const [placesService, setplacesService] = useState(null);
  const [lat,setlat]=useState(0)
  const [lng,setlng]=useState(0)
  const markerref = useRef(null);

  const mcc = React.useContext(Companyname);
  const{setMcname}=React.useContext(Companyname)

  
  
  

  useEffect(() => {
    // Define your Google Maps API key
    const googleMapsApiKey = 'AIzaSyDzBzxl_Co2GNYHCTDcPmRHKC9sAyOO1uU';

    // Create a new instance of the Loader
    const loader = new Loader({
      apiKey: googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    });

   

    // Load the Google Maps API
    loader.load().then(() => {
      // Initialize the map
      const map = new window.google.maps.Map(mapContainerRef.current, {
        center: { lat: 0, lng: 0 },
        zoom: 16,
      });

      // Set the map instance in the mapRef
      mapRef.current = map;

      // Initialize the PlacesService
    setplacesService(new window.google.maps.places.PlacesService(map));

   

      // Get the user's current location using Geolocation API
     if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(userLocation);
          setlat(position.coords.latitude)
          setlng(position.coords.longitude)
      });


      }

      // Add a click event listener to the map
      // map.addListener('click', (event) => {
      //   clearMarkers();
      //   // Create a new marker at the clicked point
      //   // Create a new marker at the clicked point
      //   mymarker(event.latLng,mapRef.current)
        
        
      //   // Center the map on the clicked point
      //   map.setCenter(event.latLng);
      //   setlat(event.latLng.lat())
      //     setlng(event.latLng.lng())

      //    // Get the current user's location
     
      // });
      let isDragging = false;

    });
  }, []);


  

  //function add marker
  const mymarker=(latlng,map)=>{
    const newMarker = new window.google.maps.Marker({
        position: latlng,
        map,
        draggable: true,
      });

      markerref.current=newMarker

      // Create an info window for the marker
const infoWindow = new window.google.maps.InfoWindow({
  content: 'Drag marker to delivery location', // Replace with your desired message or content
});



infoWindow.open(map, newMarker);


window.google.maps.event.addListener(newMarker, 'click', () => {
  // Handle the marker click event here
  // console.log('Marker clicked');
  // You can perform actions when the marker is clicked
  // For example, display an info window or open a pop-up
});


window.google.maps.event.addListener(newMarker, 'mousedown', () => {
  // Handle the marker click event here
  // console.log('Marker down');
  // You can perform actions when the marker is clicked
  // For example, display an info window or open a pop-up
});

window.google.maps.event.addListener(newMarker, 'mouseup', (event) => {
  ;
      setlat(event.latLng.lat())
    setlng(event.latLng.lng())
  // You can perform actions when the marker is clicked
  // For example, display an info window or open a pop-up
});





      // Add the new marker to the markers array
      markersRef.current.push(newMarker);

  }



   
   

  // Function to clear all markers
  const clearMarkers = () => {
    markersRef.current.forEach((marker) => {
      marker.setMap(null);
    });
    markersRef.current = [];
  };

  const handleOptionChange = (event, newValue) => {
    selection.current = false;
    setInputValue(newValue);
    const index = options.indexOf(newValue);
    placelatlong(adresses[index])
      .then((location) => {
        mapRef.current.setCenter(location);
        setlat(location.lat());
        setlng(location.lng());
        mymarker(location, mapRef.current);
      })
      .catch((error) => {
        console.error('Geocoding request failed:', error);
      });
  };
  
  const placelatlong = (nplace) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
  
      const address = nplace;
  
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();
          resolve(location);
        } else {
          reject(status);
        }
      });
    });
  };
  


   



  const handleInputChange = (event, newValue) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  
    // Simulate an API request with a delay
    timer.current = setTimeout(() => {
      // Create a Promise to handle the API request
      const searchPromise = new Promise((resolve, reject) => {
        placesService.textSearch({
          query: newValue,
        }, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const elements = [];
            const elements1 = [];
  
            for (let i = 0; i < results.length; i++) {
              elements.push(results[i].name);
              elements1.push(results[i].formatted_address);
            }
  
            resolve({ elements, elements1 });
          } else {
            reject(new Error('Places API request failed: ' + status));
          }
        });
      });
  
      // Handle the Promise with a timeout
      const timeoutDuration = 30000; // Adjust this as needed
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('API request timed out'));
        }, timeoutDuration);
      });
  
      Promise.race([searchPromise, timeoutPromise])
        .then((result) => {
          setOptions(result.elements);
          setadresses(result.elements1);
        })
        .catch((error) => {
          console.error(error);
          // Handle the error appropriately (e.g., display an error message)
        });
    }, 300);
  };
  


  function saveorder() {
    var jarray = [];
    //event.preventDefault();
    jarray.push({ mobno: mcc.mcname['mobno'], transport: 200, orderlist: mcc.mcname['cartlist'], lat: lat, lng: lng, mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/saveordercart.php'
    var b = false;
   
    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        alert('Your order has been placed')
        const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: mcc.mcname['search'], login: mcc.mcname['login'], signup: mcc.mcname['signup'], list: mcc.mcname['list'], cart: 0, cartlist: [], sno: mcc.mcname['sno'], price: mcc.mcname['price'], checkout: false, size: mcc.mcname['size'], lat: lat, lng: lng}
        setMcname(mv)
      });
  }

  return (
    <div>
     <div class="flex flex-col md:flex-row">
      {selection.current ?
  <div class="w-full md:w-1/2">
    <Autocomplete
      id="combo-box-demo"
      options={options}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Enter delivery location ..." />}
    />
  </div>
  :
  <div class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-4">
   
    <Button variant="contained" onClick={saveorder}>Confirm Delivery Location</Button>
    
  </div>}
</div>

      <div ref={mapContainerRef} style={{ height: '600px', width: '100%' }} />
    </div>
  );
};

export default Map9;
