import React, {useState,useContext} from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { Box, TextField, Container, Grid, Button, Divider} from '@mui/material';
import { Paper } from '@material-ui/core';
import validator from 'validator';

import  Companyname from "./companyname";

import './my.css';
import User from './user';



export default function Login1(props) {
const[mobno,setMobno]=useState('')
const[vmobno,setVmobno]=useState(false)
const[mmobno,setMmobno]=useState('')


const[pwd,setpwd]=useState('')
const[vpwd,setVpwd]=useState(false)
const[mpwd,setMpwd]=useState('')

const[groupn,setGroupn]=useState('USER')

const{setMcname}=useContext(Companyname)
const mcc = React.useContext(Companyname);


const handleMobno=(e)=>{
setMobno(e.target.value)
var f=e.target.value
if (validator.isMobilePhone(f) && ((f.length==10 && f[0]=="0") || (f.length==14 && f[0]=="+"))){
  setVmobno(true)
  setMmobno('')
}
else{
  setVmobno(false)
  setMmobno('')
}
}
const handlePwd=(e)=>{
  setpwd(e.target.value)
  var f=e.target.value
if (validator.isStrongPassword(f)){
  setVpwd(true)
  setMpwd('')
}
else{
  setVpwd(false)
  setMpwd('')
}

}

const handleSignup=()=>{
  const mv={ mobno: '', status1: false, status2: true, search: '', login: true, signup: true}
          
    setMcname(mv)
 
}

const handleLogin=()=>{
    if (vmobno==true && vpwd==true){
        var jarray=[]
        //event.preventDefault();
          jarray.push({mobno: mobno, pwd: pwd, mkey: 1090});
          
          const mydata = JSON.stringify(jarray);
        //send data online
         const url = 'https://www.storepoaenterprise.com/ecomerce/lib2/login.php';
         var b=false
         
       axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
         //check response
         
        if (response.data[0]['status']==1){
          const mv={ mobno: mobno, status1: false, status2: true, status3: true, search: false, login: false, signup: false, list: mcc.mcname['list'], cart: '', cartlist: mcc.mcname['cartlist'], sno:0, price:0,checkout:false,lat: 0.0, lng: 0.0}
      setMcname(mv)
               
          //const mv={ mobno: mobno, status1: true, status2: true, search: false, login: false, signup: false}
          
          //setMcname(mv)
        
        }
        else{
          alert('Invalid credentials')
        }
          
      })
      
    }
}




  return (
      <Paper  elevation={13} variant="outlined" className="shadow login1">
        {mcc.mcname['signup'] ?
        <div>
          <User />
        </div>
        :
        <div>
        <h2>Sign In</h2>
        <Divider />
    
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Mobile Number" variant="outlined" helperText={mmobno} onChange={handleMobno}/>
      </Box>
     
    <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
      <TextField id="standard-basic" label="Password" variant="outlined" inputProps={{  type: 'password'}} helperText={mpwd} onChange={handlePwd}/>
      </Box>
      
    <Divider />
    <br/>
<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='mypaper'>
<Grid item xs={6}>
  <Button variant="contained" onClick={handleLogin}>Login</Button>
</Grid>
<Grid item xs={6}>
<Button variant="contained" onClick={handleSignup}>Sign up</Button>
</Grid>

</Grid>
</div>}

</Paper>
  );
}