import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const s = document.createElement("script");
s.src =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDzBzxl_Co2GNYHCTDcPmRHKC9sAyOO1uU&libraries=places";
s.type = "text/javascript";
s.defer = true;
s.async =true;
document.body.appendChild(s);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//<script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"></script>