import React, {useState,useEffect,useContext} from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './App.css';
import Landingpage from './components/landingpage/landingpage';
import Contact from './components/navbar/contact';
import Header2 from './components/navbar/header2';
import Companyname from "./components/navbar/companyname";
import Home from "./components/home";
import Footer from "./components/footer/footer";
import PhoneAuth from "./components/sms/phoneauth";
import Map6 from "./components/maps/map6.tsx";
import Map4 from "./components/maps/map4.tsx";
import Navigator from "./components/landingpage/slider/new";
import Footer1 from "./components/footer/footer1";
import DrawerMenu from "./components/navbar/drawermenu";
import Drawer1 from "./components/navbar/drawer";
import Googlemaps from "./components/maps/googlemaps";
import Map7 from "./components/maps/map7";
import Map8 from "./components/maps/map8";
import MyTextField from "./components/maps/mytextfield";
import Map9 from "./components/maps/map9";


function App() {
  const mv={ mobno: '', status1: false, status2: false, status3: false, search: false, login: false, signup: false, list: [], cart: 0, cartlist: [], sno: 0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu:false,tcost:0}
  
  const[mcname,setMcname]=useState(mv)
  
  const mcc = React.useContext(Companyname);

  return (
   
    <Router>
      <Companyname.Provider value={{ mcname, setMcname }}>
        <div className="">
          <div className="bg-gray-100">
            
                <Contact />
                
              
          </div>
          <div className="App-body py-4 h-400">
            <Home />
            {/* <Map9/> */}
          </div>
          <Footer1 className='bottom-0'/>
        </div>
      {/* <DrawerMenu /> */}
      {/* <Drawer1 /> */}
      </Companyname.Provider>
    </Router>
  );
}



export default App;
