import React, {useContext} from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import View from "../../CategoryView/View";
import ComponentWithIcon from "./ComponentWithIcon";
//import './style.css'
import { Card } from "@material-ui/core";
import { Category } from "@material-ui/icons";
import { CatchingPokemonOutlined, FlashOnRounded } from "@mui/icons-material";
import Companyname from "../../navbar/companyname";
import { Divider, Grid } from "@mui/material";

    
      

export default function Navigator(props) {
  const mcc = useContext(Companyname);
  const{setMcname}=useContext(Companyname)

  const [data, setData] = useState([]);
  const[subcat,setSubcat] =useState([])
  const [cselect,setCselect]=useState('')
  const[dis,setDis]=useState(true)

  function loaddata() {
    var jarray = [];

    //event.preventDefault();
    //jarray.push({ pcategory: "", mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/categorylist.php' 

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        if (response.data[0]["status"] === 1) {
          setData(response.data);
          //console.log(data);
        }
      });
  }
  const fetchData = async (cat) =>{
    
    var jarray=[]
    jarray.push({category: cat, type: "category", key: '101w021'});
    const mydata = JSON.stringify(jarray);
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistcategory.php';
      try {
        
        const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
        //set responnse datasearch
        
        const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
        setMcname(mv)
      } catch (error) {
        console.error(error.message);
      }
      
    }
    const fetchDatasub = async (cat) =>{
    
      var jarray=[]
      jarray.push({category: cat, type: "category", key: '101w021'});
      const mydata = JSON.stringify(jarray);
      const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsubcategory.php';
        try {
          
          const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
          //set responnse datasearch
          
          const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
          setMcname(mv)
          console.log("products")
          console.log(response)
          
        } catch (error) {
          console.error(error.message);
        }
        
      }
  function subcategory(cat){
    var jarray = [];
    //event.preventDefault();
    jarray.push({ cat: cat, mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/subcategorylist.php'  
    
    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response
        if (response.data[0]["status"] === 1) {
          setSubcat(response.data);
          console.log(response.data)
          
        }
      });

 
  }

  useEffect(() => {
    loaddata();
  }, []);
    const [open, setOpen] = React.useState(true);
  
    const handleClick = () => {
      setOpen(!open);
 } ;
    const { ...other } = props;
  const handleCreditheader = () => {
      ReactDOM.render(
        <React.StrictMode>
          {/* <Creditheader mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("headerview")
      );
      ReactDOM.render(
        <React.StrictMode>
        {/* <Topupstat mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("contentview")
      );
      
    };
    const handleBulkheader = () => {
      ReactDOM.render(
        <React.StrictMode>
          {/* <Bulkheader mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("headerview")
      );
      ReactDOM.render(
        <React.StrictMode>
          {/* <Bulksmsstat mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("contentview")
      );
      
    };
    const handleCloseNavMenu = (category) => {
      
     // setCselect(category)
     // subcategory(category)
     // fetchData(category)
     // setDis(false)
     handlemouseenter(category)
     
    
    }

    const handlehome=()=>{
      setDis(true)
      setCselect('')
      const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
      setMcname(mv)

    }

    const handlemouseenter=(category)=>{
      setCselect(category)
      subcategory(category)
      setDis(false)
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: true}
      setMcname(mv)
    }

    const handlemouseleave=()=>{
      setDis(true)
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
      setMcname(mv)
    }

    const handlesubcategory=(subcat)=>{
      
      fetchDatasub(subcat)
      setDis(false)
    }

  return (
    <Card className="text-base font-bold text-left">
  {mcc.mcname['status1'] ? (
    <></>
  ) : (
    <div className="p-5 min-h-96">
      {dis ? (
        <div>
          <i onClick={handlehome} className="nn cursor-pointer">
            Home
          </i>
          <Divider />
          
          <ul className="list-none p-0">
          {data.map((value, index) => (
            // <div className="box  text-left flex cursor-pointer" key={index}>
            //   <span
            //     onClick={() => handleCloseNavMenu(value.category)}
            //     className="nn"
            //   >
            //     <ComponentWithIcon
            //       iconName={value.icon}
            //       className="text-yellow-500"
            //     />
            //     {value.category}
            //   </span>
            // </div>

            <li onClick={() => handleCloseNavMenu(value.category)} className="text-left block text-gray-500 hover:text-blue-700 cursor-pointer p-1">
              {/* <span
                onClick={() => handleCloseNavMenu(value.category)}
                className="nn"
              > */}
                <ComponentWithIcon
                  iconName={value.icon}
                  className="text-yellow-500"
                />
                {value.category}
              {/* </span> */}
            </li>
          ))}
          </ul>
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          className="mb-10 justify-left lg:justify-start"
        >
          <Grid
            item
            xs={0}
            sm={2}
            className="hidden lg:block"
          >
            <i onClick={handlehome} className="nn cursor-pointer">
              Home
            </i>
            
            <Divider />
            <ul>
            {data.map((value, index) => (
               <li onClick={() => handleCloseNavMenu(value.category)} className="text-left block text-gray-500 hover:text-blue-700 cursor-pointer p-1">
               {/* <span
                 onClick={() => handleCloseNavMenu(value.category)}
                 className="nn"
               > */}
                 <ComponentWithIcon
                   iconName={value.icon}
                   className="text-yellow-500"
                 />
                 {value.category}
               {/* </span> */}
             </li>
            ))}
            </ul>
          </Grid>
          <Grid
            item
            xs={0}
            sm={2}
            className="hidden lg:block text-start"
            
          >
            <i>{cselect}</i>
            <Divider />
            {subcat.map((value1, index) => (<p onClick={() => handlesubcategory(value1.sub)} className="text-blue-400 cursor-pointer p-1 hover:text-blue-700 hover:text-xl shadow-md transform skew-x-6 hover:font-semibold">
              {value1.sub}
              {/* <div className="box flex cursor-pointer text-left" key={index}>
                <span
                  onClick={() => handlesubcategory(value1.sub)}
                  className=" text-left"
                >
                  {value1.sub}
                </span>
              </div> */}
              </p>
            ))}
          </Grid>
        </Grid>
      )}
    </div>
  )}
</Card>

      
  );
}