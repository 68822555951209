import React, {useContext,useRef} from 'react'
import useState from 'react-usestateref'
import Companyname from '../navbar/companyname';
import Box from '@mui/material/Box';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Grid from '@mui/material/Grid';
import Navigator from '../landingpage/slider/new';
import './search.css'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Card } from '@mui/material';

const Searchresults = () => {
    const mcc = useContext(Companyname);
  const{setMcname}=useContext(Companyname)

  const[loading,setLoading]=useState(false)
    const[loading1,setLoading1]=useState(false)
    const[list,setList]=useState([])
    const[list1,setList1]=useState([])
    const[list2,setList2]=useState([])
    const[listvideo,setListvideo]=useState([])
    const[dimage,setDimage]=useState('')
    const[isno,setIsno]=useState(0)
    const[smenu,setsmenu]=useState(false)
    const [data, setData] = useState([]);
    const [qyt, setQyt]=useState(1)
    const[cartitems,setCartitems,catRef]=useState([])
    const[size,setSize,sref]=('')
    const[counter,setCounter,counterRef]=useState(0)

  function loaddata(sno) {
    var jarray = [];
    //event.preventDefault();
    jarray.push({ sno: sno, mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsno.php'
    var b = false;

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        if (response.data[0]["status"] == 1) {
            
          setData(response.data);
          console.log(response.data)

        }
      });
  }
  const fetchDatadesc = async (sno) =>{
    setLoading(false);
  var jarray=[]
  jarray.push({sno: sno, key: '101w021'});
  const mydata = JSON.stringify(jarray);
  const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productdesc.php';
    try {
      const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
      setList(response);
    } catch (error) {
      console.error(error.message);
    }
    setLoading(true);
  }

  const fetchDataimage1 = async (sno) =>{
    setLoading1(false);
  var jarray=[]
  jarray.push({sno: sno, key: '101w021'});
  const mydata = JSON.stringify(jarray);
  const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productimage.php';
  
    try {
      const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
      
      setList1(response);
      
      setDimage(response[0]['image'])
    } catch (error) {
      console.error(error.message);
    }
    setLoading1(true);
  }

  const fetchDatavideo = async (sno) =>{
    var jarray=[]
    jarray.push({sno: sno, key: '101w021'});
    const mydata = JSON.stringify(jarray);
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productvideo.php';
    
      try {
        const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
        
        setListvideo(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading1(true);
    }
    const fetchDataimage = async (sno) =>{
        var jarray=[]
        jarray.push({sno: sno, key: '101w021'});
        const mydata = JSON.stringify(jarray);
        const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productimage.php';
        
          try {
            const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
            
            
            
            
            Promise.resolve()
                .then(() => { setList1(response);})
                .then(() => { setDimage(response[0]['image'])})
          } catch (error) {
            console.error(error.message);
          }
      }
      const fetchDatasize = async (sno) =>{
        var jarray=[]
        jarray.push({sno: sno, key: '101w021'});
        const mydata = JSON.stringify(jarray);
        const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productsize.php';
        
          try {
            const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
            
            setList2(response);
          } catch (error) {
            console.error(error.message);
          }
      }      

  const handlecart=(sno)=>{
    const mv={ mobno: mcc.mcname['mobno'], status1: true, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: mcc.mcname['search'], login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno: sno,price: mcc.mcname['price'],pname:'',checkout:false,size:''}
    setMcname(mv)
  }

  const handleaddcart=(sno,price,pname)=>{
    
    
    var a=price
    a=a.replace(/\,/g,'')
    price=Number(a)
    var t=counter+1;
    const tcost=mcc.mcname['price'] + (price * qyt)
    let mcart={sno:sno, qyt:qyt, size:size,price:price,pname:pname}
   setCartitems([...cartitems, mcart]);
   var mm=[...mcc.mcname['cartlist'], mcart]
   var nitem=mm.length
    setCounter(t)
   const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: true, status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: nitem, cartlist: mm, sno:'',price:tcost,checkout:false,size:'',lat: 0.0, lng: 0.0}
   setMcname(mv)
   
      
  }
  const handleqyt=(e)=>{
    setQyt(e.target.value)
    
  }
  
  const productdetails=(
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={6} sm={6}>
           
          <Grid container justifyContent="center" spacing={1}>
          {data.map((item) => (
            <div>
            <Grid item xs={12} sm={12}>
            <div  key={item.pdesc}>
              <img src={item.image} alt="" style={{width: 180}}/>
              </div>
              </Grid>
              <Grid item xs={12} sm={12}>
              <div >
                  {
                  data.map((index) =>(
                      <img src={index.image} alt="" key={index} style={{width: 50}}/>
                  ))
                  }
              </div>
              </Grid>
            </div>
          ))}
          </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
          <Grid container justifyContent="center" spacing={1}>
           
          {data.map((item) => (
            
              <div  key={item.pdesc}>
                <Grid item xs={12} sm={12}>
                
                    <h4>{item.pname}</h4>
                    </Grid>  
                <Grid item xs={12} sm={12}>
                   
              <h4>Ksh {item.price}</h4>
              </Grid>
              <Grid item xs={12} sm={12}>
  
              <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="on"
    >
      <TextField  label="Quantity" variant="outlined" type="number"  value={qyt} onChange={handleqyt}/>
    </Box>
                  </Grid>
              <Grid item xs={12} sm={12}>
              <button className="button-35 " onClick={(()=>handleaddcart(item.sno,item.price,item.pname))}>
              <span  ><AddShoppingCartIcon />Add Cart</span>
              </button>
              <p> cart {mcc.mcname['cart']}</p>
                  </Grid>
              </div>
            ))}
            </Grid>
            </Grid>
            <Grid item xs={12} sm={12} >
              <h4>Product Desciption</h4>
              {mcc.mcname['cart']}
            <Grid container justifyContent="center" spacing={1}>
            {list.map((index) =>(
              <>
              <Grid item xs={4} sm={4} >
              <h5>{index.desc}</h5>
              </Grid>
              <Grid item xs={5} sm={5} >
              <p>{index.desccat}</p>
              </Grid>
              </>
            ))}
            </Grid>
                </Grid>
  
               
            
        </Grid>
      );
  
  return (
    
    <Grid item container  spacing={1} style={{width:"100%",marginBottom:10}} className='center'>
      
      <Grid item xs={0} sm={2} display={{ xs: "none", lg: "block" }} >
        <Navigator />
        </Grid> 
       
        { mcc.mcname['status1'] ?
        <Grid item xs={12} sm={10}>
        <div className='item3 shadow'>
            
            {productdetails}
        </div>
        </Grid>
        :
        <Grid item xs={12} sm={10}>
          <h1>Search Results</h1>
          {/* <Grid item container  spacing={2}  className='center '> */}
          <div className='container mx-auto '>
          <div className='grid grid-cols-3 md:grid-cols-6  '>
      {mcc.mcname['list'].map(index => (
          //  <Grid item xs={6} sm={3} >
         

          <div onClick={() => handlecart(index.sno)} className="relative m-2 max-w-xs overflow-hidden rounded-lg bg-white shadow-md flex-none  p-0 mr-2 hover:shadow-xl transition">
          <a href="#" className='flex justify-center items-center w-full'>
            <img className="md:h-30 h-20 max-h-20 md:max-h-30 rounded-t-lg object-cover" src={index.image} alt="product image" />
          </a>
          {/* <span className="absolute top-0 left-0 w-28 translate-y-4 -translate-x-6 -rotate-45 bg-black text-center text-sm text-white">For Sale</span> */}
          <div className="mt-4 px-5 pb-5">
            
          <p className="text-xs font-semibold tracking-tight text-slate-900 text-start overflow-hidden whitespace-nowrap overflow-ellipsis">{index.pname}</p>
            
            <div className="mt-2.5 mb-5 flex items-center">
              {/* <span className="mr-2 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold ">{index.rating}</span> */}
              {index.rating>0 ?
              <svg aria-hidden="true" className="h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              :
              <svg aria-hidden="true" className="h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>}
              {index.rating>1 ?
              <svg aria-hidden="true" className="h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              :
              <svg aria-hidden="true" className="h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>}
              {index.rating>2 ?
              <svg aria-hidden="true" className="h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              :
              <svg aria-hidden="true" className="h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>}
              {index.rating>3 ?
              <svg aria-hidden="true" className="h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              :
              <svg aria-hidden="true" className="h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>}
              {index.rating>4 ?
              <svg aria-hidden="true" className="h-5 w-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              :
              <svg aria-hidden="true" className="h-5 w-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>}
            </div>
            <div className="flex items-center justify-between">
              <p className='flex flex-col justify-start'>
                <span className="md:text-lg text-xs font-bold text-slate-900 text-start">KES {index.price}</span>
                {/* <span className="text-sm text-slate-900 line-through text-start">KES {index.price}</span> */}
              </p>
              
            </div>
          </div>
          </div>
          
    ))}
    </div>
    </div>
    
    {/* </Grid> */}
    </Grid>}
    
    </Grid> 
  )
}

export default Searchresults
