import React, {useContext} from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { useState, useEffect } from "react";
import axios from "axios";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Product from "../../ProductPage/Product"
import ReactDOM from "react-dom";
import '../../css/style1.css'
import Companyname from '../../navbar/companyname';

import {  CardActionArea } from '@mui/material';

function Discount(props) {
  const mcc = useContext(Companyname);
  const{setMcname}=useContext(Companyname)
  const [data, setData] = useState([]);

  function loaddata() {
    var jarray = [];

    //event.preventDefault();
    jarray.push({ category: "Supermarket", mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/productlistcategory.php'
    var b = false;

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        if (response.data[0]["status"] == 1) {
          setData(response.data);
          console.log(data);
        }
      });
  }

  useEffect(() => {
    loaddata();
  }, []);
  const handleCloseNavMenu = (sno) => {
    if(mcc.mcname['status2']==false){
      //login
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: true, signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno: 0,price: mcc.mcname['price'],pname:'',checkout:false,size:''}
      setMcname(mv)
  }
  else
  {
    
    const mv={ mobno: mcc.mcname['mobno'], status1: true, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: mcc.mcname['search'], login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno: sno,price: mcc.mcname['price'],pname:'',checkout:false,size:''}
    setMcname(mv)
  }
  }
  return (
    
    <div className="mycard" style={{backgroundColor: "white"}}>
    <Card style={{backgroundColor: "red", width: "100%"}}>
    <Typography variant='h5'>
      <i>Discount:</i>
  </Typography>
  </Card>
    <div style={{ width: "100%", overflow: "auto", display: "flex" }} className="mydiv">
      {data.map((index) =>(
        <div style={{ height: "300px", width: "223px", margin: "5px" }} className="mydiv">
        <Paper style={{ height: "100%", width: "223px" }} key={index.sno}>
        <div onClick={() => handleCloseNavMenu(index.sno)} className="item_area">
              <CardMedia
                component="img"
                height="223.5"
                width="100%"
                image={index.image}
                alt={index.pname}
              />
              <CardContent>
                <Typography gutterBottom variant="body" className="myElement" component="div">
                {index.pname}
                </Typography>
                
                <span variant="body2" color="text.secondary">
                Ksh {index.price} 
                </span>
              </CardContent>
            </div>
  
        </Paper>
      </div>
      ))}
    </div>
  </div>

  )
}

export default Discount