import * as React from 'react';
import { lazy, Suspense }  from 'react'; 
import Slider from './slider/slider';
import Discount from './discount/discount.js';
import Flashdeals from './Flashdeals/flashdeals';
import Rate from './rate/rate';
import Phones from './smartphones/phones';
import Category from './category/category';
import Tv from './Tvs/tv';
import Whatsnew from './whatsnew/whatsnew';
import Featuredproduct from './Featuredproduct/featuredproduct';
import Featuredbrand from './Featurebrand/featurebrand';
import Recommended from './Recommended/Recommended';
import Fashion from './Fashon/Fashon';
import Funiture from './Furniture/Furniture';
import Supermarket from './Supermarket/Supermarket';
import '../css/style1.css'
import Productcategorylist from './category/productcategorylist';
import axios from 'axios';


const LazyProductCategoryList = lazy(() => import('./category/productcategorylist'));

export default function Landingpage() {
  const [data, setData] = React.useState([]);

  function loaddata() {
    var jarray = [];
    //event.preventDefault();
    jarray.push({ mmkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url ='https://www.storepoaenterprise.com/ecomerce/lib3/categorylist.php'
    // const url ='https://www.storepoaenterprise.com/ecomerce/lib3/productcategorylistindex2.php'
    var b = false;

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response
        if (response.data[0]["status"] == 1) {
          setData(response.data);
          console.log(response.data)
          
         
        }
      });
  }

  React.useEffect(() => {
    console.log("category")
    loaddata();
  }, []);
  return (
    <div className='center'>
        <Slider />
        <Discount />
        <Rate />
        {data.map((categoryItem, index) => (
        <Suspense key={index} fallback={<div>Loading...</div>}>
          <LazyProductCategoryList category={categoryItem.category} />
        </Suspense>
      ))}
     

    </div>
  );
}
