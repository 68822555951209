import React, {useContext} from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import axios from "axios";
import Companyname from './companyname';
import { Card, Grid } from '@mui/material';
import ComponentWithIcon from '../landingpage/slider/ComponentWithIcon';

const drawerWidth = 400;

function Drawer1(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // const mcc = React.useContext(Companyname);
  // const{setMcname}=React.useContext(Companyname)

  // const [data, setData] = React.useState([]);
  // const[subcat,setSubcat] =React.useState([])
  // const [cselect,setCselect]=React.useState('')
  // const[dis,setDis]=React.useState(true)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // function loaddata() {
  //   var jarray = [];

  //   //event.preventDefault();
  //   //jarray.push({ pcategory: "", mkey: 1090 });
  //   const mydata = JSON.stringify(jarray);
  //   //send data online
  //   const url =
  //   'https://www.storepoaenterprise.com/ecomerce/lib3/categorylist.php' 

  //   axios
  //     .post(url, mydata, { headers: { "Content-Type": "application/json" } })
  //     .then(function (response) {
  //       //check response

  //       if (response.data[0]["status"] === 1) {
  //         setData(response.data);
  //         //console.log(data);
  //       }
  //     });
  // }
  // const fetchData = async (cat) =>{
    
  //   var jarray=[]
  //   jarray.push({category: cat, type: "category", key: '101w021'});
  //   const mydata = JSON.stringify(jarray);
  //   const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistcategory.php';
  //     try {
        
  //       const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
  //       //set responnse datasearch
        
  //       const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
  //       setMcname(mv)
  //     } catch (error) {
  //       console.error(error.message);
  //     }
      
  //   }
  //   const fetchDatasub = async (cat) =>{
    
  //     var jarray=[]
  //     jarray.push({category: cat, type: "category", key: '101w021'});
  //     const mydata = JSON.stringify(jarray);
  //     const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsubcategory.php';
  //       try {
          
  //         const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
  //         //set responnse datasearch
          
  //         const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
  //         setMcname(mv)
  //       } catch (error) {
  //         console.error(error.message);
  //       }
        
  //     }
  // function subcategory(cat){
  //   var jarray = [];
  //   //event.preventDefault();
  //   jarray.push({ cat: cat, mkey: 1090 });
  //   const mydata = JSON.stringify(jarray);
  //   //send data online
  //   const url =
  //   'https://www.storepoaenterprise.com/ecomerce/lib3/subcategorylist.php'  
    
  //   axios
  //     .post(url, mydata, { headers: { "Content-Type": "application/json" } })
  //     .then(function (response) {
  //       //check response
  //       if (response.data[0]["status"] === 1) {
  //         setSubcat(response.data);
          
          
  //       }
  //     });

 
  // }

  // React.useEffect(() => {
  //   loaddata();
  // }, []);

  // const handlehome=()=>{
  //   setDis(true)
  //   setCselect('')
  //   const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
  //   setMcname(mv)
  //   setMobileOpen(!mobileOpen);

  // }

  // const handlemouseenter=(category)=>{
  //   setCselect(category)
  //   subcategory(category)
  //   setDis(false)
  //   const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: true}
  //   setMcname(mv)
  // }

  // const handlemouseleave=()=>{
  //   setDis(true)
  //   //const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
  //   //setMcname(mv)
  // }

  // const handlesubcategory=(subcat)=>{
    
  //   fetchDatasub(subcat)
  //   setDis(false)
  //   setMobileOpen(!mobileOpen);
  // }

  // const handleCloseNavMenu = (category) => {
  //   alert(category)
      
  //   // setCselect(category)
  //   // subcategory(category)
  //   // fetchData(category)
  //   // setDis(false)
  //   handlemouseenter(category)
   
  //  }



  const mcc = useContext(Companyname);
  const{setMcname}=useContext(Companyname)

  const [data, setData] = useState([]);
  const[subcat,setSubcat] =useState([])
  const [cselect,setCselect]=useState('')
  const[dis,setDis]=useState(true)

  function loaddata() {
    var jarray = [];

    //event.preventDefault();
    //jarray.push({ pcategory: "", mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/categorylist.php' 

    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response

        if (response.data[0]["status"] === 1) {
          setData(response.data);
          //console.log(data);
        }
      });
  }
  const fetchData = async (cat) =>{
    
    var jarray=[]
    jarray.push({category: cat, type: "category", key: '101w021'});
    const mydata = JSON.stringify(jarray);
    const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistcategory.php';
      try {
        
        const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
        //set responnse datasearch
        
        const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
        setMcname(mv)
      } catch (error) {
        console.error(error.message);
      }
      
    }
    const fetchDatasub = async (cat) =>{
    
      var jarray=[]
      jarray.push({category: cat, type: "category", key: '101w021'});
      const mydata = JSON.stringify(jarray);
      const url = 'https://www.storepoaenterprise.com/ecomerce/lib3/productlistsubcategory.php';
        try {
          
          const {data: response} = await axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}});
          //set responnse datasearch
          
          const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: true, login: mcc.mcname['login'], signup: false, list: response, cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0}
          setMcname(mv)
          console.log("products")
          console.log(response)
          
        } catch (error) {
          console.error(error.message);
        }
        
      }
  function subcategory(cat){
    var jarray = [];
    //event.preventDefault();
    jarray.push({ cat: cat, mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/subcategorylist.php'  
    
    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response
        if (response.data[0]["status"] === 1) {
          setSubcat(response.data);
          console.log(response.data)
          
        }
      });

 
  }

  useEffect(() => {
    loaddata();
  }, []);
    const [open, setOpen] = React.useState(true);
  
    const handleClick = () => {
      setOpen(!open);
 } ;
    const { ...other } = props;
  const handleCreditheader = () => {
      ReactDOM.render(
        <React.StrictMode>
          {/* <Creditheader mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("headerview")
      );
      ReactDOM.render(
        <React.StrictMode>
        {/* <Topupstat mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("contentview")
      );
      
    };
    const handleBulkheader = () => {
      ReactDOM.render(
        <React.StrictMode>
          {/* <Bulkheader mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("headerview")
      );
      ReactDOM.render(
        <React.StrictMode>
          {/* <Bulksmsstat mobileno={props.mobileno} /> */}
        </React.StrictMode>,
        document.getElementById("contentview")
      );
      
    };
    const handleCloseNavMenu = (category) => {
      
     // setCselect(category)
     // subcategory(category)
     // fetchData(category)
     // setDis(false)
     handlemouseenter(category)
     
    
    }

    const handlehome=()=>{
      setDis(true)
      setCselect('')
      const mv={ mobno: mcc.mcname['mobno'], status1: false, status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
      setMcname(mv)

    }

    const handlemouseenter=(category)=>{
      setCselect(category)
      subcategory(category)
      setDis(false)
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: true}
      setMcname(mv)
    }

    const handlemouseleave=()=>{
      setDis(true)
      const mv={ mobno: mcc.mcname['mobno'], status1: mcc.mcname['status1'], status2: mcc.mcname['status2'], status3: mcc.mcname['status3'], search: false, login: mcc.mcname['login'], signup: false, list: mcc.mcname['list'], cart: mcc.mcname['cart'], cartlist: mcc.mcname['cartlist'], sno:0,price:0,pname:'',checkout:false,lat: 0.0, lng: 0.0, menu: false}
      setMcname(mv)
    }

    const handlesubcategory=(subcat)=>{
      
      fetchDatasub(subcat)
      setDis(false)
      handleDrawerToggle()
      handlehome()
    }

  const drawer = (
    <div className="text-base  text-left align-left">
  {mcc.mcname['status1'] ? (
    <></>
  ) : (
    <Card className="p-5 min-h-96">
      {dis ? (
        <div className="mx-auto text-left">
          <i onClick={handlehome} className="nn cursor-pointer">
            Home
          </i>
          <Divider />
          {data.map((value, index) => (
            <div className="box flex cursor-pointer" key={index}>
              <span
                onClick={() => handleCloseNavMenu(value.category)}
                className="nn"
              >
                <ComponentWithIcon
                  iconName={value.icon}
                  className="text-yellow-500"
                />
                {value.category}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          className="mb-10 justify-left lg:justify-start"
        >
          <Grid
            item
            xs={2}
            sm={2}
            className="hidden lg:block"
          >
            <i onClick={handlehome} className="nn cursor-pointer">
              Home
            </i>
            <Divider />
            {data.map((value, index) => (
              <div className="box flex cursor-pointer" key={index}>
                <span
                  onClick={() => handleCloseNavMenu(value.category)}
                  className="nn"
                >
                  <ComponentWithIcon
                    iconName={value.icon}
                    className="text-yellow-500"
                  />
                  {value.category}
                </span>
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={0}
            sm={6}
            
          >
            <i onClick={handlehome} className="nn cursor-pointer">
              Back{'<<'}
              <Divider />
            </i>
            <i>{cselect}</i>
            <Divider />
            {subcat.map((value1, index) => (<p>
              <div className="box flex cursor-pointer" key={index}>
                <span
                  onClick={() => handlesubcategory(value1.sub)}
                  className="nn"
                >
                  {value1.sub}
                </span>
              </div>
              </p>
            ))}
          </Grid>
        </Grid>
      )}
    </Card>
  )}
</div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="text-left">
      <CssBaseline />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
        </Toolbar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        
      </Box>
      
    </Box>
  );
}

Drawer1.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Drawer1;
