import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import image1 from "./images/brandnew.png";
import image2 from "./images/laptop.png";
import image3 from "./images/mobile.png";
import image4 from "./images/pharmacy.png";
import image5 from "./images/slide_5.jpg";

const styles = {
    paperContainer1: {
        backgroundImage: `url(${image1})`,
        '&:hover': {
            opacity: [0.9, 0.8, 0.7],
        },
    },
    paperContainer2: {
        backgroundImage: `url(${image2})`,
        '&:hover': {
            opacity: [0.9, 0.8, 0.7],
        },
    },
    paperContainer3: {
        backgroundImage: `url(${image3})`,
        '&:hover': {
            opacity: [0.9, 0.8, 0.7],
        },
    },
    paperContainer4: {
        backgroundImage: `url(${image4})`,
        '&:hover': {
            backgroundImage: [0.9, 0.8, 0.7],
        },
    },
    paperContainer5: {
        backgroundImage: `url(${image2})`,
        '&:hover': {
            opacity: [0.9, 0.8, 0.7],
        },
    }
};
export default function Category() {
    return (
        <Grid item xs={12}>
            <Typography variant='h4'>
                Category
            </Typography>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item>
                    <Box
                        style={styles.paperContainer1}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer2}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer3}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer4}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer5}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer1}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
                <Grid item>
                    <Box
                        style={styles.paperContainer1}
                        sx={{
                            borderRadius: '16px',
                            width: 48,
                            height: 48,
                            // dackgroundImage: 'primary.dark',
                            // '&:hover': {
                            //   backgroundColor: 'primary.main',
                            //   opacity: [0.9, 0.8, 0.7],
                            // },
                        }}
                    />
                    <Typography>
                        Laptop
                        </Typography>
                </Grid>
            </Grid>
        </Grid>

    );
}