import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Slide } from 'react-slideshow-image';
import image1 from "./images/slide_1.jpg";
import image2 from "./images/slide_2.jpg";
import image3 from "./images/slide_3.jpg";
import image4 from "./images/slide_4.jpg";
import image5 from "./images/slide_5.jpg";
import banner1 from "./images/banner1.png"
import banner2 from "./images/banner2.png"
import './index.css';
import './style.css';
import Card from '@mui/material/Card';
import Navigation from './new'
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import { mockComponent } from "react-dom/test-utils";
import Companyname from "../../navbar/companyname";


// style was imported in index.css
// import "react-slideshow-image/dist/styles.css";
const images = [banner1,banner2];

const zoomOutProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  scale: 0.4,
  arrows: false
};

const Slideshow = (props) => {
  

  return (
    <div   className='mycard1'>
      <Card >
      <Slide {...zoomOutProperties}>
        {props.data.map((each, index) => (
          <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} sm={12} >
          <img alt="" key={index} style={{  width: "100%"}} src={each.image} />
          </Grid>
          </Grid>
        ))}
      </Slide>
      </Card>
    </div>
  );
};

function Slider() {
  const [data, setData] = useState([]);
  const mcc = React.useContext(Companyname);
  const{setMcname}=React.useContext(Companyname)

  function loaddata() {
    var jarray = [];

    //event.preventDefault();
    //jarray.push({ pcategory: "", mkey: 1090 });
    const mydata = JSON.stringify(jarray);
    //send data online
    const url =
    'https://www.storepoaenterprise.com/ecomerce/lib3/banner.php'    
    var b = false;
    axios
      .post(url, mydata, { headers: { "Content-Type": "application/json" } })
      .then(function (response) {
        //check response
        if (response.data[0]["status"] == 1) {
          setData(response.data);
          
        }
      });
  }

  useEffect(() => {
    loaddata();
  }, []);
  return (
    <Grid container  spacing={1} style={{width:"100%",marginBottom:10}} className='center'>
     
    {mcc.mcname['menu'] ?
    <Grid item xs={0} sm={12} display={{ xs: "none", lg: "block" }} >
    <Navigation />
  </Grid>
  :
  <>
      <Grid item xs={0} sm={2} display={{ xs: "none", lg: "block" }} >
          
      <Navigation />
    </Grid>
     <Grid item xs={12} sm={10} className="mycard1">
      
      <Slideshow data={data}/>
    
    </Grid>
    </>}
   
    </Grid>
  );
}

export default Slider;